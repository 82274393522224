<template>
  <div>
    <section class="ftco-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6 text-center mb-5">
            <div class="heading-section text-dark p-2">
              {{$t("useraut.slogan1")}}
            </div>
            <a :href="SITE_URL" class="urlclass">{{ SITE_URL }}</a>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-7 col-lg-5">
            <div class="login-wrap p-4 p-md-5">
              <div class="d-flex">
                <div class="w-100">
                  <h3 class="mb-4 text-dark d-flex align-items-center justify-content-center">{{$t("useraut.changepassword")}}</h3>
                </div>
              </div>
              <form action="#" class="login-form" v-on:submit.prevent="newpassword">
                <div class="form-group" data-kt-password-meter="true">
                  <div class="position-relative mb-3">
                    <div class="icon d-flex align-items-center justify-content-center">
                      <span class="fa fa-lock"></span>
                    </div>
                    <input class="form-control rounded-left" type="password" :disabled="disabled == 1"
                      :placeholder="$t('useraut.password')" v-model.lazy="password" name="password" autocomplete="off" required />
                    <!--begin::Visibility toggle-->
                    <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                      data-kt-password-meter-control="visibility">
                      <i class="bi bi-eye-slash fs-2"></i>
                      <i class="bi bi-eye fs-2 d-none"></i>
                    </span>
                    <!--end::Visibility toggle-->
                  </div>
                  <div class="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
                    <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                    <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                    <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                    <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                </div>
                </div>
                <div class="form-group">
                  <div class=" icon d-flex align-items-center justify-content-center">
                    <span class="fa fa-lock"></span>
                  </div>
                  <input type="password" :disabled="disabled == 1" class="form-control rounded-left" name="rpassword"
                    v-model="rpassword" :placeholder="$t('useraut.confirm')" required />
                  <small v-if="!$v.rpassword.sameAs" class="form-text text-danger">Şifreler aynı değil!</small>
                </div>
                <div class="form-group d-flex align-items-center">
                  <div class="w-100 d-flex justify-content-end">
                    <button type="submit" :disabled="disabled == 1" class="btn btn-primary rounded submit">
                      {{$t("useraut.changepassword")}}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import DataService from "../services/DataService";
  import Encrypt from "../services/Encrypt";
  import { required, minLength, sameAs } from "vuelidate/lib/validators";

  export default {
    name: "New-Password",
    metaInfo: function() {
    return {
      title: this.$t("metatag.titledefault"),
      titleTemplate: "%s | "+this.$t("metatag.titleDetailHome"),
      meta: [
      { name: 'description', content:  this.$t("metatag.description") },
      { name: 'keywords', content:  this.$t("metatag.keywords") }
      ]
    }
  },
    data() {
      return {
        password: "",
        rpassword: "",
        disabled: 0,
        term: "",
        browser: "",
        browserLang: "",
        platform: "",
        SITE_URL: process.env.VUE_APP_SITE_URL,
      };
    },
    validations: {
      password: {
        required,
        minLength: minLength(8),
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial
          );
        },
      },
      rpassword: {
        required,
        minLength: minLength(8),
        sameAs: sameAs("password"),
      },
    },
    methods: {
      sessionKontrol() {
        if (this.$session.get("logtoken")) {
          window.location.href = `/${this.$session.get("lang")}/dashboard`;
        }
      },
      userActivity() {
        DataService.userActivity().then((response) => {
          this.term = response.data.ip;
        });
        this.browser = navigator.userAgent;
        this.browserLang = navigator.language;
        this.platform = navigator.platform;
      },
      async createToken(
        data0 = "null",
        data1 = "null",
        data2 = "null",
        data3 = "null",
        data4 = "null",
        data5 = "null",
        data6 = "null",
        data7 = "null",
        data8 = "null",
        data9 = "null",
        data10 = "null"
      ) {
        var send = [];
        await DataService.veriSifrele([
          data0,
          data1,
          data2,
          data3,
          data4,
          data5,
          data6,
          data7,
          data8,
          data9,
          data10,
        ]).then((response) => {
          send.push(response.data);
        });
        return send;
      },
      async newpassword() {
        if (this.password != "" && this.rpassword != "") {
          if (this.$v.password.valid) {
            if (this.$v.$invalid === false) {
              var pathname = window.location.pathname.split("/"),
                key = pathname[4];
              DataService.newpassword({
                token: await this.createToken(key, Encrypt.encrypt(this.password), Encrypt.encrypt(this.rpassword)),
                activity: await this.createToken(
                  this.term,
                  this.browser,
                  this.browserLang,
                  this.platform
                ),
              }).then((response) => {
                if (!response.data.error && response.data.status == "ok") {
                  this.$vToastify.setSettings({
                    position: "bottom-right",
                    theme: "light",
                    successDuration: 10000,
                  });
                  this.$vToastify.success(this.$t('useraut.passchange'));
                  this.$router.push(`/${this.$session.get("lang")}/login`);
                  this.disabled = 1;
                } else {
                  this.password = "";
                  this.rpassword = "";
                  this.$vToastify.setSettings({
                    position: "bottom-right",
                    theme: "light",
                  });
                  this.$vToastify.warning(
                    "",
                    this.$t("message." + response.data.error)
                  );
                  this.$router.push(`/${this.$session.get("lang")}/login`);
                }
              });
            } else {
              this.$vToastify.setSettings({
                position: "bottom-right",
                theme: "light",
              });
              this.$vToastify.warning(this.$t('useraut.errorform'));
            }
          } else {
            this.$vToastify.setSettings({
              position: "bottom-right",
              theme: "light",
            });
            this.$vToastify.warning(this.$t('useraut.strongpassword'));
          }
        } else {
          this.$vToastify.setSettings({
            position: "bottom-right",
            theme: "light",
          });
          this.$vToastify.warning(this.$t('useraut.blankfield'));
        }
      },
    },
    mounted() {
      this.userActivity();
      this.sessionKontrol();
    },
  };
</script>
<style lang="" scoped src="../assets/css/login.css">
</style>