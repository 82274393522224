<template>
  <div>
    <Passwordnew></Passwordnew>
  </div>
</template>

<script>
// @ is an alias to /src
import Passwordnew from '@/components/Passwordnew.vue'

export default {
  name: 'newpasswordview',
  components: {
    Passwordnew,
  }
}
</script>
